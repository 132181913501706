import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "kyleBoe",
  "categories": ["rails", "upgrade"],
  "date": "2019-04-23",
  "path": "/blog/moderate-parameters",
  "summary": "We all know Strong Parameters isn't exactly cutting edge technology. But with many apps still using Protected Attributes, we built a tool to bridge the gap. We call it Moderate Parameters.",
  "title": "Moderate Parameters: The Path From Protected Attributes to Strong Parameters",
  "image": "./ogp.png",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@kbiz"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We all know Strong Parameters isn't exactly cutting edge technology in 2019. It was originally introduced in Rails 3.2 (March 20, 2012) and was made the default/standard in 4.0 (June 25, 2013).`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "650px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "95.0920245398773%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAAE5klEQVQ4yyWTWXNTBQCFrzCIoiBCBUvL0hZis3RLmjTN2iw3y03vmntzsze0tFRKqUVKBVxQEaVgKW5FXABfnFHHYRxGedMXngSXX/Q5xYfz+s2Z880R5hcmOfXaUV6da/L9T/f57fe/+fnXh6x9fou1zz7li5vrrN9c5/qNVa6sfMCl99/kwvkTzEyX0JU0UnqYbMxBKtKFoYQRpqarTM1UqU8YXLz0AT/88gf3Hjxk/fZdVlavcunyZRZOztIoG9RtnalWldML0yyenmQ8HyEd6yMV6kZKuGhUCgilsky1oVNvmUxMlfnx3gM+/+pbrt1Y4+Pra0w1y2SCbiwx8iTR/iMUxkaoGBKGmkUS/WjSKDVTpFIUEeyKQq1pMHW8hl1TWFm9xtXVq7z5zkUW5mfJRQaYrzb45Nz7rC+/wblGmboUxUgFMaUMJTVFNtFPQfShj48i2FWZetPg1ZPHnrRcWn6NlZUrLJyaRxaDlKUMlxfPc2PpHF8vL3Hr9Bwzyhj1QhRTDNFQElj5ERIhB6YSQbDsAqaVo94qYlVl3nrrDB9eegdNSiJGvczWGny0eJYbZ8/z7bllvls+xUk9TSUbpCqNUc7FOKqnKKlxbC2GYNoypl2g2tAw7AJnzsyzeGIa/0APvv5uMuEAC7USFyZt5qwCV060OGVmqIoB9ESACTXJbClLsyRR0eMIhjWOVZaxyxrVWpFms4JWGMNxaBep6CjHJ2b4dHGB1RN1ZuwiizWZuWISOzGE6HdQyY8yW0rTKmWxi0mEoqlgmHlKZZ1Wq85Eo0wy4iXsc9EsmixNznLn9Bx3z8xza3mJq3MNlsoZCkE3ab+TSm6U41aKhpnFNvMIZklF1SV0o0C9alKvFFHHM+RTUcbTCfLhISazQV63JT6arvHelMGMFkUK95ENuqnkQ1TyESZtlWZV3bBsoFsyRUulVrGwiiqakkdKhYn0O0h6nWS8DvTYALOWyDEjRTHlR44NkBtxURL/33GmotOsahtAjQ1ofaKGZWgoUppcIkwuNoSRGqGZS1KK+ZDDfejJYazsKGrCSz7kJhNwUUz6mTKSHKuqNCoqgqqL1GoWupYnJ8bJJkPEgn3EAx7kdJDx+DCpYTfZoIf08CtIEQ+5YC+pgIN00IUa89HSxqgWM5TNHIKiZtE1FVFMMOg5iH/wMK4j++hzdhINuBns7cTds5cRTyc+xx68PbuJew/S27WbyGAXlVyAaTNN3di4Xg4hI2XwB3wkYgGc3W0c7NjJ7l3baWt7HmfPHnq7X6Kncwd9h9sY6HqR/gM7aW97lp3btxB0dqCGjnBUjdHU09R1EWHvoZdxOg4w5j3AK53b2LZVYNNTAk8JAk9vFtj7wmZefE6ga9cm+vZspmOHQPuup3lhq0DvgR1k/d0oUQ/Z0ABybgzh2Z3PMDJ4hIx/HxFPO7qcwVJENClBMhFGzkTJbEgSY6TCgyi5MYpygvF8nLqtoiV8pEZ6SQZdJBMBhG3bn6Hf2UXc24Ecc/Ho0WP+/PMRf//zL+ufrHHx/DL379/n8eO/uP3NV9y5c5v33n2bL29+wfVrK5SkEBFvB8nRLsYLfoTNWzbR2bEbn6udUP8+lKSTuuJjQvFRlKL4BxwMufYz4jlE1N1ONuwh6O1lyL2f+PBhVHEYWQ6jKVFaTYP/AIzl2o2LFVjRAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "image",
        "title": "image",
        "src": "/static/a1c2a00be739027341934957249b0899/a6d36/whatyearisit.png",
        "srcSet": ["/static/a1c2a00be739027341934957249b0899/222b7/whatyearisit.png 163w", "/static/a1c2a00be739027341934957249b0899/ff46a/whatyearisit.png 325w", "/static/a1c2a00be739027341934957249b0899/a6d36/whatyearisit.png 650w", "/static/a1c2a00be739027341934957249b0899/8ae3e/whatyearisit.png 756w"],
        "sizes": "(max-width: 650px) 100vw, 650px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <p>{`Even with Strong Parameters 7 years in the rear view mirror, there was still legacy security support in the form of the `}<inlineCode parentName="p">{`protected_attributes`}</inlineCode>{` gem. However, with the release of Rails 5, this gem was officially unmaintained.`}</p>
    <p>{`Based on our `}<a parentName="p" {...{
        "href": "https://www.upgraderails.com"
      }}>{`UpgradeRails`}</a>{` experience helping clients move to the latest and greatest versions of Rails, this is still a large technical hurdle that many teams face.`}</p>
    <p>{`With this in mind, we built a tool that allows for safe and confident migration to strong parameters.`}</p>
    <p>{`We call it Moderate Parameters.`}</p>
    <h2>{`Here's How It Works`}</h2>
    <p>{`Moderate Parameters is a tool that provides safety nets and logging of data sources in the controller by extending `}<inlineCode parentName="p">{`ActionController::Parameters`}</inlineCode>{` functionality.`}</p>
    <p>{`Add the gem (`}<a parentName="p" {...{
        "href": "https://github.com/hintmedia/moderate_parameters"
      }}>{`repo`}</a>{`/`}<a parentName="p" {...{
        "href": "https://rubygems.org/gems/moderate_parameters"
      }}>{`rubygems`}</a>{`) to your application's Gemfile:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'moderate_parameters'`}</span></code></pre></div>
    <p>{`Then, inside of the recommended strong parameters private method, replace `}<inlineCode parentName="p">{`permit`}</inlineCode>{` with `}<inlineCode parentName="p">{`moderate`}</inlineCode>{` and add a little context:`}</p>
    <p><em parentName="p">{`(Note:`}</em>{` `}<inlineCode parentName="p">{`controller_path`}</inlineCode>{` `}<em parentName="p">{`and`}</em>{` `}<inlineCode parentName="p">{`action_name`}</inlineCode>{` `}<em parentName="p">{`need to be included as shown. They are methods that provide naming context for the logger.)`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UsersController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActionController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Base`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# def user_params`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   params.require(:user).permit(:email, :first_name, . . .)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`user_params`}</span></span>{`
    params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:user`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`moderate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`controller_path`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` action_name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:email`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:first_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`And...that's it.`}</p>
    <p>{`Moderate Parameters will begin logging controller context and parameter keys that have not yet been whitelisted. This means that, in the above example, if you forgot to include `}<inlineCode parentName="p">{`:last_name`}</inlineCode>{` in the list of permitted parameters while creating a user, your log would look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "log"
    }}><pre parentName="div" {...{
        "className": "language-log"
      }}><code parentName="pre" {...{
          "className": "language-log"
        }}>{`Users#create is missing :last_name from the permitted parameters`}</code></pre></div>
    <h2>{`Test Like You Fly`}</h2>
    <p>{`We have built Moderate Parameters to be deployed to production. This way we can ensure that all parameters are being accounted for every controller in the entire Rails app. Data will still flow through the app as expected, with the added benefit of painting a clear picture of what moving to Strong Parameters **looks like.`}</p>
    <p>{`Once the full parameter list has been clearly outlined (meaning your Moderate Parameters logs are empty), switching over to Strong Parameters is as easy as swapping `}<inlineCode parentName="p">{`moderate`}</inlineCode>{` for `}<inlineCode parentName="p">{`permit`}</inlineCode>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UsersController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ActionController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Base`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`user_params`}</span></span>{`
    params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:user`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:email`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:first_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:last_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# def user_params`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   params.require(:user).moderate(controller_path, action_name, :email, :first_name, :last_name)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <h2>{`We Can Help!`}</h2>
    <p>{`We have built up a set of services that can run alongside Moderate Parameters in production to procedurally parse and generate exactly what Strong Parameters should look like.`}</p>
    <p>{`If Strong Parameters or something like it is preventing you or your team from enjoying the latest in Rails security and features, let us help you ensure your success. Our team has decades of experience helping teams turn unmanageable upgrades into cutting-edge products taking advantage of all that Rails has to offer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      